<template>
  <span class="font-weight-bold">{{ roundValue(value) }}</span>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    mediaMinima: {
      type: [Number],
      default: 7,
    },
    max: {
      type: Number,
      default: 10,
    },
    arredondamento: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val > this.max) {
        this.$emit("change", parseFloat(this.max, 10));
      }
    },
  },
  created() {
    if (this.value > this.max) {
      this.$emit("change", parseFloat(this.max, 10));
    }
  },
  computed: {
    valueAsNumberOrNull() {
      return !this.valueIsEmpty ? parseFloat(this.value, 10) : null;
    },
    valueIsEmpty() {
      if (!this.value) {
        return "- - -";
      }
      // eslint-disable-next-line no-restricted-globals
      return this.value === null || this.value === undefined || isNaN(this.value);
    },
    formattedValue() {
      if (!this.value) return "- - -";
      let val = parseFloat(this.value);
      if (this.arredondamento) {
        val = this.roundValue(val);
      }
      return val <= this.max ? Math.floor(val * 10) / 10 : this.max;
    },
  },
  methods: {
    roundValue(val) {
      if (this.arredondamento === 1) {
        if (!this.value) return "- - -";
        const decimalPart = val % 1;
        if (decimalPart < 0.25) {
          return Math.floor(val);
        }
        if (decimalPart >= 0.25 && decimalPart < 0.75) {
          return Math.floor(val) + 0.5;
        }
        return Math.ceil(val);
      }
      if (!this.value) return "- - -";
      return val.toFixed(1);
    },
  },
};
</script>
